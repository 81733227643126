<!-- 
	This is the DataTables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>

	<div>

        <div v-if="!loadingPage">
        
            <a-card :bordered="false" class="header-solid mb-24 px-25" :bodyStyle="{padding: 0, paddingTop: '16px'}">
                <a-row class="mx-25 pt-10">
                    <a-col :span="12">
                        <h5 class="font-semibold">Teacher Profile</h5>
                    </a-col>
                    <a-col :span="12" class="text-right">
                        <a-button type="primary" href="/staff-management/teachers" class="px-20 mb-10">
                            <a-icon type="arrow-left" theme="outlined" class="text-white mr-5" />Back 
                        </a-button>
                    </a-col>

                    <a-col :span="24">
                        <a-divider class="mt-0 pt-0"/>
                    </a-col>
                </a-row>

                <a-row type="flex" align="middle" class="mx-25 pb-25">
                    <a-col :span="10" :sm="6" :md="3" :lg="2">
                        <a-avatar :size="74" shape="square" src="images/face-1.jpg" />
                    </a-col>

                    <a-col :span="14" :sm="10" :md="11" :lg="12">
                        <h5 class="font-semibold m-0 mt-5">
                            {{ teacher.firstName }} {{ teacher.middleName != null ? `${teacher.middleName[0].toUpperCase()}.` : '' }} {{ teacher.lastName }}
                        </h5>
                        <p class="pt-2 mb-0 pb-0">{{ teacher.dateOfBirth != null ? `${getAge(teacher.dateOfBirth)} years old` : '-' }}</p>
                        <!-- <p class="mt-0 pt-0">{{ 'Standard 7' }}</p> -->
                    </a-col>

                    <a-col :span="24" :sm="8" :md="10" :lg="10" class="text-right">
                        <small class="mr-5">Status</small>
                        <a-switch default-checked class="bg-success" v-model="teacher.isActive" />
                    </a-col>

                </a-row>

            </a-card>

            
            <div class="mt-15 pt-10">
                    <a-row type="flex" :gutter="24">
                        <a-col :span="24">
                            <a-tabs class="tabs-sliding primary" v-model="activeTab" @change="onTabChange">

                                <a-tab-pane :key="1" tab="Basic Details">
                                    
                                    <a-card class="header-solid px-25 mb-25">
                                        <div class="mx-25 pb-0 mb-0" v-if="!teacher.loading">
                                            <a-row>
                                                <a-col :span="12" class="pt-15">
                                                    <h5 class="font-semibold mb-0 pb-0 mt-10">Basic Details</h5>
                                                </a-col>

                                                <a-col :span="12" class="pt-15 text-right">
                                                    <a-button v-if="teacher.disabled" id="txtBtn" type="text" class="pb-0 pt-0 mb-10" size="large" style="color: #FB8C00;" @click="onEditBasicDetails">
                                                        <a-icon type="form" /> Edit
                                                    </a-button>
                                                </a-col>

                                                <a-col :span="24">
                                                    <a-divider class="mt-0 pt-0"/>
                                                </a-col>

                                            </a-row>
                                            <a-row :gutter="24">
                                                <a-col :span="24" :md="24">
                                                    <a-form-model
                                                        :model="teacher"
                                                        :form="form"
                                                        class="login-form mb-0 pb-0"
                                                        layout="vertical"
                                                        @submit.prevent="handleSubmit">

                                                        <a-row :gutter="24">
                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="First Name *"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['firstname', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="teacher.firstName" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Middle Name"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['middleName', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="teacher.middleName" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Last Name *"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['lastName', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="teacher.lastName" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                        </a-row>

                                                        <a-row :gutter="24">

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Employee No"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['employeeNo', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="teacher.employeeNo" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Employee Type"
                                                                    :colon="false">
                                                                        <a-select 
                                                                            show-search
                                                                            v-model="teacher.employeeTypeUuid" 
                                                                            placeholder="Select Employee Type"
                                                                            :options="employeeTypes.map(option => ({ label: option.name, value: option.uuid }))" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Date of Appointment"
                                                                    :colon="false">
                                                                        <a-date-picker
                                                                            v-model="teacher.dateOfAppointment2" 
                                                                            format="DD MMM YYYY"
                                                                            style="width: 100%"
                                                                            placeholder="" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                        </a-row>

                                                        <a-row :gutter="24">
                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Salutation"
                                                                    :colon="false">
                                                                        <a-select 
                                                                            show-search
                                                                            v-model="teacher.salutationUuid" 
                                                                            placeholder="Select Salutation"
                                                                            :options="salutations.map(option => ({ label: option.name, value: option.uuid }))" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>


                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="User"
                                                                    :colon="false">
                                                                        <a-select 
                                                                            show-search
                                                                            v-model="teacher.userUuid" 
                                                                            placeholder="Select User"
                                                                            :options="users.map(option => ({ label: `${option.firstName} ${option.lastName}`, value: option.uuid }))" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>


                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Gender *"
                                                                    placeholder="Select Gender"
                                                                    :colon="false">
                                                                    <a-select v-model="teacher.gender" placeholder="Select Gender">
                                                                        <a-select-option v-for="option in genders"
                                                                            :value="option.uuid"
                                                                            :key="option.name">
                                                                            {{ option.name }}
                                                                        </a-select-option>
                                                                    </a-select>
                                                                </a-form-item>
                                                            </a-col>
                                                        </a-row>


                                                        <a-row :gutter="24">

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Marital Status"
                                                                    placeholder="Select Marital Status"
                                                                    :colon="false">
                                                                    <a-select v-model="teacher.maritalStatus" placeholder="Select Marital Status">
                                                                        <a-select-option v-for="option in maritalStatuses"
                                                                            :value="option"
                                                                            :key="option">
                                                                            {{ option }}
                                                                        </a-select-option>
                                                                    </a-select>
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Email Address"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['email', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="teacher.email" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>


                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Phone Number"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['phone', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="teacher.phone" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>
                                                        </a-row>

                                                        <a-row :gutter="24">

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Secondary Email Address"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['secondaryEmail', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="teacher.secondaryEmail" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>


                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Secondary Phone Number"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['secondaryPhone', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="teacher.secondaryPhone" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>
                                                            

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Date of Birth"
                                                                    :colon="false">
                                                                        <a-date-picker
                                                                            v-model="teacher.dateOfBirth2" 
                                                                            format="DD MMM YYYY"
                                                                            style="width: 100%"
                                                                            placeholder="" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                        </a-row>

                                                        <a-row :gutter="24">

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Religion"
                                                                    :colon="false">
                                                                    <a-select v-model="teacher.religion" placeholder="Select Religion" :disabled="teacher.disabled">
                                                                        <a-select-option v-for="option in religions"
                                                                            :value="option"
                                                                            :key="option">
                                                                            {{ option }}
                                                                        </a-select-option>
                                                                    </a-select>
                                                                </a-form-item>
                                                            </a-col>

                                                            
                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Nationality"
                                                                    :colon="false">
                                                                        <a-select 
                                                                            show-search
                                                                            :disabled="teacher.disabled"
                                                                            v-model="teacher.nationality" 
                                                                            placeholder="Select Country"
                                                                            :options="countries.map(option => ({ label: option.label, value: option.label }))" />
                                                                </a-form-item>
                                                            </a-col>

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Picture of Student (Passport Size)"
                                                                    :colon="false">
                                                                        <a-input 
                                                                            v-decorator="['teacherPicture', { rules: [{ required: true, message: 'Please input your note!' }] }]"
                                                                            v-model="teacher.teacherPicture" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                        </a-row>

                                                        <a-row :gutter="24">

                                                            <a-col :span="8" :lg="8" :md="8" :sm="8">
                                                                <a-form-item 
                                                                    class="mt-20 mb-10 pb-0" 
                                                                    label="Address"
                                                                    :colon="false">
                                                                        <a-textarea :rows="4" placeholder="" v-model="teacher.address" :disabled="teacher.disabled"/>
                                                                </a-form-item>
                                                            </a-col>

                                                        </a-row>

                                                        <a-row :gutter="24">
                                                            <a-col :span="24" :lg="24" :md="24" :sm="24">
                                                                <a-form-item class="mt-20"> 
                                                                    <a-button v-if="!teacher.disabled" type="secondary" @click="getTeacherDetails" class="mr-25">
                                                                        Cancel
                                                                    </a-button>
                                                                    <a-button v-if="!teacher.disabled" type="primary" :loading="teacher.loading" html-type="submit" class="ml-25">
                                                                        {{ teacher.isCreate ? 'Save' : 'Update' }}
                                                                    </a-button>
                                                                </a-form-item>
                                                            </a-col>
                                                        </a-row>

                                                    </a-form-model>
                                                </a-col>
                                            </a-row>
                                        </div>

                                        <div v-if="teacher.loading">
                                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">
                                                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
                                                    <a-spin size="large" />
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </a-card>

                                </a-tab-pane>

                                <a-tab-pane :key="2" tab="Teaching Subjects">

                                    <a-card class="header-solid px-25 mb-25">
                                        <div class="mx-25 pb-0 mb-0">
                                            <a-row>
                                                <a-col :span="12" class="pt-15">
                                                    <h5 class="font-semibold mb-0 pb-0 mt-10">List of Teaching Subjects</h5>
                                                </a-col>

                                                <a-col :span="12" class="pt-15 text-right">
                                                    <a-button type="primary" @click="onCreateTeacherSubject" class="px-20 mb-10">
                                                        <a-icon type="plus" theme="outlined" class="text-white mr-5" />Add Subject 
                                                    </a-button>
                                                </a-col>

                                                <a-col :span="24">
                                                    <a-divider class="mt-0 pt-0"/>
                                                </a-col>

                                            </a-row>
                                            <a-row :gutter="24">
                                                <a-col :span="24" :md="24">
                                                    <a-table class="mt-20"  
                                                        size="middle"
                                                        :bordered="false"
                                                        :columns="teachingSubjectColumns" 
                                                        :data-source="teacherSubjects" 
                                                        :rowKey="record => record.uuid"
                                                        :loading="teacherSubjectPagination.loading" 
                                                        :pagination="{pageSize: 10}">

                                                            <template slot="s_no" slot-scope="s_no, record, index">
                                                                {{ index + 1 }}
                                                            </template>

                                                            <template slot="subject" slot-scope="subject">
                                                                {{ subject != null ? subject.name : '-' }}
                                                            </template>

                                                            <template slot="grades" slot-scope="grades, record">
                                                                <a-badge  
                                                                    v-for="grade in record.grades"
                                                                    :key="grade.uuid"
                                                                    style="font-size: 10px !important" 
                                                                    :count="grade.name" 
                                                                    :number-style="{ marginRight: '10px', backgroundColor: '#52C41A', color: '#FFFFFF'}"/>
                                                            </template>
                                                            
                                                            <template slot="createdAt" slot-scope="createdAt">
                                                                {{ $Moment(createdAt).format("DD MMM YYYY") }}
                                                            </template>

                                                            <template slot="action" slot-scope="action, record">
                                                                <a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #FB8C00;" @click="onEditTeacherSubject(record)">
                                                                    <a-icon type="form" /> Edit
                                                                </a-button>
                                                                &nbsp;&nbsp;
                                                                <a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #F44335;" @click="showTeacherSubjectDeletionConfirmation(record.uuid)">
                                                                    <a-icon type="delete" theme="outlined"/> Delete
                                                                </a-button>
                                                            </template>
                                                        
                                                    </a-table>
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </a-card>

                                </a-tab-pane>
                                
                                <!-- <a-tab-pane :key="3" tab="Classrooms">

                                    <a-card class="header-solid" :bodyStyle="{padding: '50px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                                        <a href="#" class="text-center text-muted font-bold">
                                            <h6 class="font-semibold text-muted">Empty Tab</h6>
                                        </a>
                                    </a-card>

                                </a-tab-pane>
                                
                                <a-tab-pane :key="4" tab="Working Experience">

                                    <a-card class="header-solid" :bodyStyle="{padding: '50px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                                        <a href="#" class="text-center text-muted font-bold">
                                            <h6 class="font-semibold text-muted">Empty Tab</h6>
                                        </a>
                                    </a-card>

                                </a-tab-pane>                -->

                            </a-tabs>

                        </a-col>
                    </a-row>
            </div>

        </div>

        <div v-if="loadingPage">
            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">
                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
                    <a-spin size="large" />
                </a-col>
            </a-row>
        </div>


        <!-- TEACHER FORM MODAL -->
		<a-modal v-model="teacherSubject.showModal" :footer="null">
			<h4 class="text-center">{{ teacherSubject.isCreate ? 'Add Subject' : 'Edit Subject'}}</h4>
			<a-form-model
				:model="teacherSubject"
                :form="form"
                class="login-form mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmitTeacherSubject">

                <a-form-item 
                    class="mt-20 mb-10 pb-0" 
                    label="Subjects *"
                    :colon="false">
                        <a-select 
                            show-search
                            v-model="teacherSubject.subjectUuid" 
                            placeholder="Select Subject"
                            :options="subjects.map(option => ({ label: `${option.name} (${option.abbreviation})`, value: option.uuid }))" />
                </a-form-item>

                <a-form-item 
                    class="mt-20 mb-10 pb-0" 
                    label="Grades *"
                    :colon="false">
                        <a-select 
                            mode="tags"
                            show-search
                            v-model="teacherSubject.grades" 
                            placeholder="Select Grades"
                            :options="grades.map(option => ({ label: `${option.name} (${option.level.name})`, value: option.uuid }))" />
                </a-form-item>

                <a-form-item class="mt-20"> 
                    <a-button type="primary" :loading="teacherSubject.loading" html-type="submit" class="">
                        {{ teacherSubject.isCreate ? 'Create' : 'Update' }}
                    </a-button>
                </a-form-item>

			</a-form-model>
			
		</a-modal>

        <!-- TEACHER SUBJECT DELETE CONFIRMATION -->
		<a-modal v-model="deleteDataTeacherSubject.showModal" :footer="null" width="416px">

			<a-row :gutters="24" class="">
				<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
					<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
				</a-col>

				<a-col :span="24" :md="21" :lg="21" :xl="21">

					<a-row :gutters="24">
						<a-col :span="24">
							<h6>Do you want to delete this data?</h6>
							<p>Once you delete, you will not be able to recover this inforamtion.</p>
						</a-col>
					</a-row>

					<a-row :gutters="24">
						<a-col :span="24" class="text-right">
							<a-button id="txtBtn" type="text" class="text-secondary" @click="cancelTeacherSubjectDeletion">
								Cancel
							</a-button>
							&nbsp;&nbsp;
							<a-button id="txtBtn" type="text" class="text-danger" @click="onDeleteTeacherSubject">
								 Delete
							</a-button>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
      
		</a-modal>

	</div>

</template>

<script>

    import { Countries } from '../Resources/Countries'

    const countries = Countries;

	// Second table's list of columns.
	const teachingSubjectColumns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'SUBJECT',
			dataIndex: 'subject',
			// sorter: (a, b) => a.firstName.length - b.firstName.length,
			// sortDirections: ['descend', 'ascend'],
			scopedSlots: { customRender: 'subject' },
		},
        {
			title: 'GRADES',
			dataIndex: 'grades',
			scopedSlots: { customRender: 'grades' },
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			align: "center"
		},
	];

	
	export default {
		components: {
		},
		data() {
			return {
                activeTab: 1,
				academicPagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10
                },
                teacherSubjectPagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10
                },
                form: this.$form.createForm(this, { name: 'newItemForm' }),

				genders: [
					{ uuid: "Male", name: "Male" },
					{ uuid: "Female", name: "Female" },
				],

				countries,
				religions: ["Christian", "Muslim", 'Buddhist', "Hindu", "Others", "Non-Religious"],
				maritalStatuses: ["Single", "Married", 'Widowed', "Others"],
				users: [],
				salutations: [],
				employeeTypes: [],
				teachingSubjectColumns,
				message: '',

                loadingPage: false,

				teacher: {
                    uuid: null,
					userUuid: null,
					employeeTypeUuid: null,
					salutationUuid: null,
					employeeNo: null,
					firstName: null,
					middleName: null,
					lastName: null,
					gender: null,
					email: null,
					phone: null,
					secondaryEmail: null, 
					secondaryPhone: null,
					address: null,
					dateOfBirth: null,
					dateOfBirth2: null,
					maritalStatus: null,
					religion: null,
					nationality: null,
					dateOfAppointment: null,
					dateOfAppointment2: null,
					teacherPicture: null,
                    pictureUrl: null,
                    isActive: true, 
					isCreate: false,
                    disabled: true,
					loading: false,
					showModal: false,
				},

                academics: [],
                academic: {
					uuid: null,
                    teacherUuid: this.$route.params.uuid,
                    classroomUuid: null,
                    admissionNumber: null,
                    admissionDate: null,
                    isActive: false,
                },

                subjects: [],
                grades: [],
                teacherSubjects: [],
                teacherSubject: {
					uuid: null,
                    teacherUuid: this.$route.params.uuid,
                    subjectUuid: null,
					grades: [],
					isCreate: false,
					loading: false,
					showModal: false,
                },

                deleteDataTeacherSubject: {
					uuid: null,
					showModal: false,
				},
			
			}
		},
		created() {
			this.getAllDetails();
            this.getActiveTab()
			this.getEmployeeTypes();
			this.getSalutations();
			this.getUsers();
            this.getSubjects();
            this.getGrades();
		},
		methods: {

            async onTabChange(event) {
                await localStorage.setItem("teacherActiveTab", event.toString())
            },

            async getActiveTab() {
                let activeTab = await localStorage.getItem("teacherActiveTab")

                if(activeTab) {
                    this.activeTab = parseInt(activeTab);
                }
            },

            async getAllDetails() {
                await this.getTeacherDetails();
                await this.getTeacherSubjects();
            },

			getTeacherDetails() {
                this.teacher.loading = true;

                let url = `${this.$BACKEND_URL}/teachers/${this.$route.params.uuid}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.teacher.disabled = true;
                        this.teacher.uuid = response.data.uuid;
                        this.teacher.userUuid = response.data.user != null ? response.data.user.uuid : null;
                        this.teacher.employeeTypeUuid = response.data.type != null ? response.data.type.uuid : null;
                        this.teacher.salutationUuid = response.data.salutation != null ? response.data.salutation.uuid : null;
                        this.teacher.employeeNo = response.data.employeeNo;
                        this.teacher.firstName = response.data.firstName;
                        this.teacher.middleName = response.data.middleName;
                        this.teacher.lastName = response.data.lastName;
                        this.teacher.gender = response.data.gender;
                        this.teacher.email = response.data.email;
                        this.teacher.phone = response.data.phone;
                        this.teacher.secondaryEmail = response.data.secondaryEmail; 
                        this.teacher.secondaryPhone = response.data.secondaryPhone;
                        this.teacher.address = response.data.address;
                        this.teacher.dateOfBirth = response.data.dateOfBirth;
                        this.teacher.dateOfBirth2 = response.data.dateOfBirth;
                        this.teacher.maritalStatus = response.data.maritalStatus;
                        this.teacher.religion = response.data.religion;
                        this.teacher.nationality = response.data.nationality;
                        this.teacher.dateOfAppointment = response.data.dateOfAppointment;
                        this.teacher.dateOfAppointment2 = response.data.dateOfAppointment;
                        this.teacher.isActive = true; //response.data.isActive;
                    }

                    this.teacher.loading = false;
                    this.loadingPage = false;
                }).catch((error) => {
                    console.log(error)
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    this.loadingPage = false;
                    this.teacher.loading = false;
                });
            },


            getSalutations() {
                const currentPage = 1; 
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/salutations/?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.salutations = response.data.data;
                        }
                    }
                    
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    
                });
            },

			getUsers() {
                const currentPage = 1; 
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/users/?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.users = response.data.data;
                        }
                    }
                    
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    
                });
            },


			getEmployeeTypes() {

                const currentPage = 1; 
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/employee-types/?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.employeeTypes = response.data.data;
                        }
                    }
                    
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    
                });
            },

            getGrades() {

                const currentPage = 1; 
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/grades/?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.grades = response.data.data;
                        }else{
                            let message = "There is no data available"
							let type = "success"

                    		this.notify(type, message)
                        }
                    }
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)
                });
            },


            getSubjects() {

                const currentPage = 1; 
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/subjects/?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.subjects = response.data.data;
                        }else{
                            let message = "There is no data available"
							let type = "success"

                    		this.notify(type, message)
                        }
                    }
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)
                });
            },

            

			getAge (birthDate) {
				var years = new Date(new Date() - new Date(birthDate)).getFullYear() - 1970;

				return years
			},

            onEditBasicDetails() {
                this.teacher.disabled = false;
            },

            async handleSubmit() {

                this.teacher.loading = true;
    
                let url = `${this.$BACKEND_URL}/teachers/${this.$route.params.uuid}`

                this.teacher.dateOfBirth = this.teacher.dateOfBirth2 != null && this.teacher.dateOfBirth2.$d != null ? this.$Moment(this.teacher.dateOfBirth2.$d).format("YYYY-MM-DD") : this.teacher.dateOfBirth2; 
                this.teacher.dateOfAppointment = this.teacher.dateOfAppointment2 != null && this.teacher.dateOfAppointment2.$d != null ? this.$Moment(this.teacher.dateOfAppointment2.$d).format("YYYY-MM-DD") : this.teacher.dateOfAppointment2; 

                this.$AXIOS.patch(url, this.teacher).then(response => {

                    if (response.status >= 200 && response.status < 210) {

                        this.form.resetFields()

                        this.teacher.loading = false;
                        this.teacher.disabled = true;

                        let message = response.data.message
                        let type = "success"

                        this.notify(type, message)

                        this.getTeacherDetails();

                    }
    
                }).catch(error => {
                
                    this.teacher.loading = false;
        
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)
                });
				
			},


            //TEACHER SUBJECT
            getTeacherSubjects() {
                this.teacherSubjectPagination.loading = true;

                const currentPage = 1;
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/teachers/subject/${this.$route.params.uuid}/?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.teacherSubjects = response.data.data;
                    }
                    this.teacherSubjectPagination.loading = false;
                    
                }).catch((error) => {
                    this.teacherSubjectPagination.loading = false;

                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                });
            },

            onCreateTeacherSubject() {
                this.teacherSubject.uuid = null;
                this.teacherSubject.teacherUuid = this.$route.params.uuid;
                this.teacherSubject.subjectUuid = null;
                this.teacherSubject.grades = [];
				this.teacherSubject.isCreate = true;
				this.teacherSubject.loading = false;
				this.teacherSubject.showModal = true;
            },

			onEditTeacherSubject(item) {
                this.teacherSubject.uuid = item.uuid;
                this.teacherSubject.teacherUuid = this.$route.params.uuid;
                this.teacherSubject.subjectUuid = item.subject.uuid;
                this.teacherSubject.grades = item.grades.map(grade => grade.uuid);
				this.teacherSubject.isCreate = false;
				this.teacherSubject.loading = false;
				this.teacherSubject.showModal = true;
            },


            async handleSubmitTeacherSubject() {

				if(this.teacherSubject.isCreate) {

					this.teacherSubject.loading = true;
		
					let url = `${this.$BACKEND_URL}/teachers/subject`
					
					this.teacherSubject.uuid = undefined;

					this.$AXIOS.post(url, this.teacherSubject).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.form.resetFields()

							this.teacherSubject.uuid = null;
                            this.teacherSubject.teacherUuid = this.$route.params.uuid;
                            this.teacherSubject.subjectUuid = null;
                            this.teacherSubject.grades = null;
							this.teacherSubject.isCreate = true;
							this.teacherSubject.loading = false;
							this.teacherSubject.showModal = false;

							let message = response.data.message
							let type = "success"

							this.notify(type, message)

						}

						this.getTeacherSubjects()

					}).catch(error => {
					
						this.teacherSubject.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						let type = "danger"

						this.notify(type, message)
			
					});

				}else{

					this.teacherSubject.loading = true;
		
					let url = `${this.$BACKEND_URL}/teachers/subject/${this.teacherSubject.uuid}`

					this.$AXIOS.patch(url, this.teacherSubject).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.form.resetFields()

							this.teacherSubject.uuid = null;
                            this.teacherSubject.teacherUuid = this.$route.params.uuid;
                            this.teacherSubject.subjectUuid = null;
                            this.teacherSubject.grades = null;
							this.teacherSubject.isCreate = true;
							this.teacherSubject.loading = false;
							this.teacherSubject.showModal = false;

							let message = response.data.message
							let type = "success"

							this.notify(type, message)

						}

						this.getTeacherSubjects()
		
					}).catch(error => {
					
						this.teacherSubject.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						let type = "danger"

						this.notify(type, message)
					});
				}
				
			},

            showTeacherSubjectDeletionConfirmation(uuid) {
				this.deleteDataTeacherSubject.uuid = uuid;
				this.deleteDataTeacherSubject.showModal = true;
			},

			cancelTeacherSubjectDeletion() {
				this.deleteDataTeacherSubject.uuid = null;
				this.deleteDataTeacherSubject.showModal = false;
			},

			onDeleteTeacherSubject() {

				this.deleteDataTeacherSubject.loading = true;

                let url = `${this.$BACKEND_URL}/teachers/subject/${this.deleteDataTeacherSubject.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDataTeacherSubject.uuid = null;
					this.deleteDataTeacherSubject.showModal = false;
                
                    this.deleteDataTeacherSubject.loading = false;

					let message = response.data.message;
                    let type = "success"

                    this.notify(type, message)

					this.getTeacherSubjects();

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    this.deleteDataTeacherSubject.loading = false;
                });
			},

			notify(type, message) {
				this.$notification.open({
					class: 'ant-notification-' + type,
					message: message,
					duration: 3.0,
					style: {fontSize: "10px !important"}
				});
			},

		},

		
	}

</script>

<style lang="scss">

	#txtBtn, #txtBtn:hover {
		border: none;
	}
    .ant-input[disabled], .ant-select-disabled {
        color: #414141;
        font-weight: 400;
    }
</style>